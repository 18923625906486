import "../styles/globals.css";
import { useEffect } from "react";

import { ApolloProvider } from "@apollo/client";
import { NoSsr } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/next";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import { NextPage } from "next";

import { ColumnLayout } from "shared/layouts/ColumnLayout";
import { isReactNativeWebview } from "shared/lib/reactNativeWebviewUtils";
import { AuthProvider } from "shared/providers/AuthProvider";
import { ColorModeProvider } from "shared/providers/ColorModeProvider";
import { UnreadRequestsProvider } from "shared/providers/UnreadRequestsProvider";
import { SnackbarProvider } from "shared/toast/SnackbarProvider";

import PageHead from "../components/PageHead";
import client from "../lib/apollo-client";
import { ShopSetupProvider } from "../providers/ShopSetupProvider";

import type { AppProps } from "next/app";

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_KEY);

interface CustomMessageEvent extends MessageEvent {
  data: string;
}

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const routesRequireLogo = ["/auth/signin", "/auth/invite"];

function MyApp({ Component, pageProps, router }: AppPropsWithLayout) {
  const disableAuth =
    router.asPath.startsWith("/auth/") ||
    router.asPath.startsWith("/onboarding");

  const showColumnLayoutWithLogo = routesRequireLogo.some((route) =>
    router.asPath.includes(route)
  );

  const handleMessage = (event: CustomMessageEvent) => {
    if (event.data === "refresh") {
      client.refetchQueries({
        include: "active",
        onQueryUpdated: (observableQuery) => {
          const result = observableQuery.refetch();
          if (isReactNativeWebview(window)) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                messageType: "refreshComplete",
              })
            );
          }
          return result;
        },
      });
    }
  };

  useEffect(() => {
    if (isReactNativeWebview(window)) {
      window.addEventListener("message", handleMessage);
    }
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <NoSsr>
      <PageHead />
      <AuthProvider routeRequiresAuth={disableAuth} loginPath="/auth/signin">
        <ApolloProvider client={client}>
          <Elements stripe={stripePromise}>
            <ColorModeProvider>
              <SnackbarProvider>
                <UnreadRequestsProvider>
                  <ShopSetupProvider disableProvider={disableAuth}>
                    {showColumnLayoutWithLogo ? (
                      <ColumnLayout showLogo={showColumnLayoutWithLogo}>
                        <Component {...pageProps} />
                      </ColumnLayout>
                    ) : (
                      <Component {...pageProps} />
                    )}
                  </ShopSetupProvider>
                </UnreadRequestsProvider>
              </SnackbarProvider>
            </ColorModeProvider>
          </Elements>
        </ApolloProvider>
      </AuthProvider>
      <Analytics />
      <SpeedInsights />
    </NoSsr>
  );
}

export default withLDProvider<AppProps & JSX.IntrinsicAttributes>({
  clientSideID: process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_KEY,
  options: {
    streaming: true,
  },
})(MyApp);
